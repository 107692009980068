<!--
 * ======================================
 * 说明： 教师端 - 学业水平
 * 作者： Silence
 * 文件： study.vue
 * 日期： 2023/8/3 0:32
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <div class="p-6">
    <div class="my-4">
      <el-form :inline="true" :model="formSearch" class="web-form-inline">
        <SemesterClassSelect v-model="formSearch" @onLoadFinish="handleLoadDataInfo" @change="handleLoadDataInfo" @refresh="handleLoadDataInfo"></SemesterClassSelect>
      </el-form>
    </div>
    <el-tabs v-model="activeName" type="border-card" v-if="options.length" @tab-click="handleLoadDataInfo">
      <el-tab-pane :label="menu.label" :name="index.toString()" v-for="(menu,index) in options" :key="index">
        <div v-if="menu.evaluate">
          <block-score-list :options="options[index].children" :block-id="formSearch.blockId" :semesterId="formSearch.semesterId"
                            :grade="formSearch.grade" :classId="formSearch.classId"/>
        </div>
        <div v-else>
          <Scrollbar>
            <otherGrades ref="otherGrades" v-model="formSearch" :file-name="menu.label" :headers="menu.children"
                         v-if="activeName==index"/>
          </Scrollbar>
        </div>
      </el-tab-pane>
    </el-tabs>
    <el-empty v-else description="暂无任务数据"></el-empty>
  </div>
</template>
<script type="text/javascript">

import otherGrades from "./components/otherGrades";
import blockScoreList from "./components/blockScoreList.vue";

export default {
  name: "medal",
  components: {
    otherGrades,
    blockScoreList
  },
  data() {
    return {
      //搜索表单结构
      formSearch: {
        semesterId: "",//学期ID
        blockId: "",//模块ID
        grade: '',//年级ID
        classId: '',//班级ID
        subjectId: 3,//任课ID
        realName: ''
      },
      activeName: '0',
      options: [],
      blockOptions: []
    };
  },
  /**
   * 接收父级传递的数据
   */
  inject: ["WEB"],
  methods: {
    handleLoadDataInfo() {
      let menuInfo = this.WEB.getMenuInfo();
      this.formSearch.blockId = menuInfo["block_id"];
      this.formSearch.subjectList = this.WEB.userInfo.subjectIdList
      this.handleStudyOption();
      setTimeout(() => {
        console.log('otherGrades',this.$refs.otherGrades[0])
        if(this.$refs.otherGrades[0]){
          this.$refs.otherGrades[0].handleLoadDataList()
        }
      },200)
    },
    /**
     * 获取数据选项
     */
    handleStudyOption() {
      //获取分页数据结构
      return this.$cloud.get("task/study", this.formSearch).then(res => {
        console.log('activeName',this.activeName)
        this.options = [];
        if (res.length && res[0]) {
          this.options = res[0]?.children;
          // this.blockOptions = res[this.activeName]?.children[this.activeName]?.children;
        }
      }).catch(err => {
        this.options = [];
      })
    },
  },
};
</script>
<style lang="scss">
.el-tabs--border-card > .el-tabs__content {
  padding: 0;
}
</style>
